import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Arrow,
  DataSetDelete,
  DatasetDimensionsWrapper,
  DatasetHeading,
  DatasetHeadingWrapper,
  DatasetRowContainer,
  DataSetUploadedOn,
  DataSetWrapper,
  ErrorIcon,
  ErrorMessageWrapper,
  PreviewButton,
  PreviewOpenedDimensions,
  RowWrapper,
  Slash,
  TrashIcon,
} from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { getActiveModal } from "../../../../../../store/selectors/modals";
import {
  getSelectedDataset,
  getSelectedFiles,
  getSelectedFileView,
} from "../../../../../../store/selectors/datasets";
import {
  FileLibraryItem,
  ParsedSuggestions,
} from "../../../../../../models/Files";
import {
  removeAllSelectedFiles,
  removeSelectedFile,
  requestDatasetRemove,
  setDatasetPreview,
  setOverviewFileData,
  updateSelectedFiles,
} from "../../../../../../store/slices/datasets";
import { DatasetFiledDTO } from "../../../../../../models/Datasets";
import { useDebounceCallback, useHover } from "usehooks-ts";
import { removeFileData } from "../../../../../../store/slices/files";
import { CheckBox } from "../../../../../Inputs/CustomCheckbox/CheckBox";
import Badge from "../../../../../Badge";
import { getIconSrc } from "../../../../../../helpers/getIconType";
import { DimensionsCounts } from "../../../../../DimensionsCounts";

type CheckboxStatus = "checked" | "indeterminate" | "none";

type Props = {
  setFileOverviewOpen: Dispatch<SetStateAction<boolean>>;
  setDimensionsSelected: Dispatch<SetStateAction<any[]>>;
  file: FileLibraryItem;
  selectAllProp?: CheckboxStatus;
  libraryDatasetOverviewOpen?: boolean;
  selectedDataSet: string[];
  setSelectedDataSet: Dispatch<SetStateAction<string[]>>;
};

const MyDataRow = ({
  file,
  setFileOverviewOpen,
  setDimensionsSelected,
  libraryDatasetOverviewOpen,
  selectedDataSet,
  setSelectedDataSet,
}: Props) => {
  const dispatch = useDispatch();
  const currentStage = useSelector(getActiveModal);
  const selectedFiles = useSelector(getSelectedFiles);

  const [selectedDimensions, setSelectedDimensions] = useState<any>([]);
  const [showAllDatasets, setShowAllDatasets] = useState<boolean>(false);

  const datasetLibraryModalOpened = currentStage.includes(
    "datasetLibraryModal"
  );
  const datasetOverviewModalItem = useSelector(getSelectedFileView);
  const selectedFilesIds = useMemo(
    () => selectedFiles.map((file) => file.id),
    [selectedFiles]
  );
  const librarySelectedDataset = useSelector(getSelectedDataset);
  const suggestedData = file.parsedSuggestions;
  const fields = file.fields;

  const datasetLibraryFileData = fields;
  const totalErrors = suggestedData?.reduce((acc, item) => {
    return acc + (item.types.length > 1 ? 1 : 0);
  }, 0);

  const idsIncluded =
    selectedFilesIds.includes(file.path) ||
    selectedFilesIds.includes(file.id || "") ||
    selectedFilesIds.includes(file.sourceId || "");

  const ref = useRef<any>();
  const debounced = useDebounceCallback(setShowAllDatasets, 3000);

  const isHover = useHover(ref);

  const dimensionsToShow = () => {
    return datasetLibraryModalOpened
      ? showAllDatasets
        ? fields
        : fields?.slice(0, 3)
      : showAllDatasets
      ? suggestedData
      : suggestedData?.slice(0, 3);
  };

  const handleSelect = (element: any, suggestedType: any, key: any) => {
    setSelectedDimensions((prevState: any) => {
      const existingIndex = prevState.findIndex(
        (item: any) => item.key === key
      );
      if (existingIndex !== -1) {
        return prevState.filter((item: any) => item.key !== key);
      } else {
        const type = Array.isArray(suggestedType)
          ? suggestedType[0].type
          : suggestedType.type;
        const subtype = Array.isArray(suggestedType)
          ? suggestedType[0].subtype
          : suggestedType.subtype;
        return [
          ...prevState,
          {
            label: element,
            type: type,
            subtype: subtype,
            key: key,
            icon: type,
            explainedColumns:
              suggestedType?.explained_columns ||
              suggestedType.explainedColumns,
          },
        ];
      }
    });
  };

  const handleBadgeClick = (dimension: ParsedSuggestions | DatasetFiledDTO) => {
    if ("types" in dimension && dimension.types.length > 1) {
    } else {
      const suggestedType =
        "types" in dimension
          ? dimension.types[0]
          : { type: dimension.type, subtype: dimension.subtype };
      const key =
        "objectKey" in dimension ? dimension.objectKey : dimension.key;

      handleSelect(dimension.label, suggestedType, key);
    }
  };

  const toggleSelectAll = (state?: boolean) => {
    setSelectedDimensions(() => {
      if (fields?.length! > selectedDimensions.length && !state) {
        if (datasetLibraryModalOpened) {
          return datasetLibraryFileData?.map((dimension) => ({
            label: dimension.label,
            type: dimension.type,
            subtype: dimension.subtype,
            key: dimension.key,
            icon: dimension.type,
            explainedColumns:
              dimension?.explained_columns || dimension.explainedColumns,
          }));
        } else {
          return suggestedData
            ?.map((dimension) => {
              const suggestedType = Array.isArray(dimension.types)
                ? dimension.types[0]
                : dimension.types;
              return Array.isArray(dimension.types) &&
                dimension.types.length === 1
                ? {
                    label: dimension.label,
                    type: suggestedType.type,
                    subtype: suggestedType.subtype,
                    key: dimension.objectKey,
                    icon: suggestedType.type,
                    explainedColumns:
                      suggestedType?.explained_columns ||
                      suggestedType.explainedColumns,
                  }
                : null;
            })
            .filter(Boolean);
        }
      } else {
        return [];
      }
    });
  };

  const onRemove = () => {
    if (datasetLibraryModalOpened) {
      dispatch(removeAllSelectedFiles());
      setSelectedDimensions([]);
      dispatch(requestDatasetRemove(file.id || ""));
      dispatch(setDatasetPreview({}));
    } else {
      dispatch(removeSelectedFile(file.path));
      dispatch(removeFileData(file.path));
      dispatch(setOverviewFileData({}));
    }
    setFileOverviewOpen(false);
  };

  const onPreview = () => {
    datasetLibraryModalOpened
      ? dispatch(setDatasetPreview(file))
      : dispatch(setOverviewFileData(file));
    setFileOverviewOpen(true);
    setDimensionsSelected(
      selectedDimensions.map((item: any) => {
        return item.label;
      })
    );
  };

  useEffect(() => {
    dispatch(
      updateSelectedFiles({
        id: file.path ? file.path : file.id || "",
        fields: selectedDimensions,
      })
    );
    setDimensionsSelected(
      selectedDimensions.map((item: any) => {
        return item.label;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimensions, file]);

  useEffect(() => {
    if (isHover && showAllDatasets) {
      return debounced(true);
    }
    return debounced(false);
  }, [debounced, isHover, showAllDatasets]);

  const date = new Date(String(file.createdAt));

  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  useEffect(() => {
    if (selectedDataSet.includes(file.id!)) {
      if (selectedDimensions.length !== fields?.length) {
        toggleSelectAll();
      }
    } else {
      toggleSelectAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDataSet]);

  const handleOnChange = () => {
    if (selectedDataSet.includes(file.id!)) {
      setSelectedDataSet(selectedDataSet.filter((item) => item !== file.id!));
    } else {
      setSelectedDataSet([...selectedDataSet, file.id!]);
    }
  };

  return (
    <DatasetRowContainer
      ref={ref}
      $datasetLibraryModalOpened={datasetLibraryModalOpened}
      $datasetOverviewModalOpened={
        file.path === datasetOverviewModalItem.path &&
        file.id === librarySelectedDataset.id
      }
      $selected={fields?.length === selectedDimensions.length || idsIncluded}
      onMouseLeave={() => showAllDatasets && debounced(false)}
    >
      <RowWrapper
        onClick={() => (libraryDatasetOverviewOpen ? onPreview() : {})}
        style={{ cursor: libraryDatasetOverviewOpen ? "pointer" : "auto" }}
      >
        <DataSetWrapper>
          <DatasetHeadingWrapper>
            <CheckBox
              onChange={handleOnChange}
              checked={!!selectedDimensions.length}
              status={
                fields?.length === selectedDimensions.length
                  ? "checked"
                  : !!selectedDimensions.length
                  ? "indeterminate"
                  : "none"
              }
            />
            <DatasetHeading>
              {file.id ? file.name : file.originalName}
            </DatasetHeading>
          </DatasetHeadingWrapper>
          {!libraryDatasetOverviewOpen && (
            <DatasetDimensionsWrapper>
              {dimensionsToShow()!.map(
                (
                  dimension: ParsedSuggestions | DatasetFiledDTO,
                  idx: number
                ) => {
                  return (
                    <Badge
                      name={dimension.label}
                      onClick={() => {
                        handleBadgeClick(dimension);
                      }}
                      icon={getIconSrc(
                        "types" in dimension
                          ? dimension.types[0]
                          : { type: dimension.type, subtype: dimension.subtype }
                      )}
                      key={idx}
                      selectedItems={selectedDimensions.map(
                        (item: any) => item.label
                      )}
                      errorDetected={
                        "types" in dimension && dimension.types.length > 1
                      }
                    />
                  );
                }
              )}
              <DimensionsCounts
                data={suggestedData ? suggestedData : fields}
                showAllDatasets={showAllDatasets}
                setShowAllDatasets={setShowAllDatasets}
              />

              {/* {showPreview && ( */}
              {libraryDatasetOverviewOpen ? (
                ""
              ) : (
                <PreviewButton
                  $error={false}
                  $disabled={false}
                  onClick={onPreview}
                >
                  <Arrow />
                  <Slash />
                  Preview
                  <Slash />
                  <Arrow />
                </PreviewButton>
              )}

              {/* )} */}
            </DatasetDimensionsWrapper>
          )}

          {totalErrors ? (
            <ErrorMessageWrapper>
              <ErrorIcon />
              {totalErrors} errors detected
            </ErrorMessageWrapper>
          ) : (
            ""
          )}
        </DataSetWrapper>
        <DataSetUploadedOn>{formattedDate}</DataSetUploadedOn>
        <DataSetDelete>
          <TrashIcon onClick={onRemove} />
        </DataSetDelete>
      </RowWrapper>

      {libraryDatasetOverviewOpen && (
        <PreviewOpenedDimensions>
          <DatasetDimensionsWrapper>
            {dimensionsToShow()!.map(
              (dimension: ParsedSuggestions | DatasetFiledDTO, idx: number) => {
                return (
                  <Badge
                    name={dimension.label}
                    onClick={() => {
                      handleBadgeClick(dimension);
                    }}
                    icon={getIconSrc(
                      "types" in dimension
                        ? dimension.types[0]
                        : { type: dimension.type, subtype: dimension.subtype }
                    )}
                    key={idx}
                    selectedItems={selectedDimensions.map(
                      (item: any) => item.label
                    )}
                    errorDetected={
                      "types" in dimension && dimension.types.length > 1
                    }
                  />
                );
              }
            )}
            <DimensionsCounts
              data={suggestedData ? suggestedData : fields}
              showAllDatasets={showAllDatasets}
              setShowAllDatasets={setShowAllDatasets}
            />

            {/* {showPreview && ( */}
            {libraryDatasetOverviewOpen ? (
              ""
            ) : (
              <PreviewButton
                $error={false}
                $disabled={false}
                onClick={onPreview}
              >
                <Arrow />
                <Slash />
                Preview
                <Slash />
                <Arrow />
              </PreviewButton>
            )}

            {/* )} */}
          </DatasetDimensionsWrapper>
        </PreviewOpenedDimensions>
      )}
    </DatasetRowContainer>
  );
};

export default MyDataRow;
