import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { createPortal } from "react-dom";
import {
  axisBottom,
  axisLeft,
  scaleBand,
  scaleLinear,
  select,
  scaleSqrt,
} from "d3";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  SVGStyled,
  Title,
} from "./styles";
import { HeadingNameAndButton } from "../styles";

import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { getActiveModal } from "../../../store/selectors/modals";

import {
  calculateLabelLength,
  calculateNumTicks,
  getScaleBandTickValues,
} from "../widgetHelpers";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { PunchcardChartGroupedData } from "./utils/getGroupData";
import { getCurrentColor } from "../utils/getCurrentMarker";
import { generateColorRanges } from "./utils/generateColorRanges";
import { ColorRangeI } from "../../../models/Pages";
import { LabelTooltip } from "../components/LabelTooltip";
import { getSequentialColorsHex } from "../../../constants/utils/getSequentialColors";

export interface PunchcardChartProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  preview?: boolean;
}

const bubbleLabelFormatter = (value: number): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(value as number);

const tickFormatter = (value: string, length: number = Infinity): string => {
  const splitedValue = value?.split("");

  return `${splitedValue?.slice(0, length).join("")}${
    splitedValue?.length < length + 1 ? "" : "..."
  }`;
};

export const PunchcardChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
  preview = false,
}: PunchcardChartProps) => {
  const dispatch = useDispatch();
  const svgRef = useRef<any>(null);
  const xAxisRef = useRef<any>(null);
  const [refWidget, boundsWidget] = useMeasure();
  const [measureRef, bounds] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [values, setValues] = useState<number[]>([]);
  const [variations, setVariations] = useState<string[]>([]);
  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [tickLabelTooltip, setTickLabelTooltip] = useState<{
    data: string;
    x: number;
    y: number;
  } | null>(null);

  const maxLengthYAxisTickLabel = 9; // same as on the matrix chart
  const minBubbleRadius = 8;
  const minBubbleRadiusVisibleLabel = 16;
  const maxBubbleRadius = 30;

  const chartData: any = useMemo(() => {
    return currentWidget?.data;
  }, [currentWidget?.data]);

  const chartSuggestion = useMemo(() => {
    return aiSuggestions?.find(
      (chart: AiSuggestionsDto) => chart.chartType === "punchcardChart"
    );
  }, [aiSuggestions]);

  const groupBy = useMemo(() => {
    return currentWidget?.groupBy?.[0] || chartSuggestion?.groupBy;
  }, [chartSuggestion?.groupBy, currentWidget?.groupBy]);

  const xAxe = useMemo(() => {
    return currentWidget?.xAxe?.[0] || chartSuggestion?.xAxe?.[0];
  }, [chartSuggestion?.xAxe, currentWidget?.xAxe]);

  const yAxe = useMemo(() => {
    return currentWidget?.yAxe?.[0] || chartSuggestion?.yAxe?.[0];
  }, [chartSuggestion, currentWidget]);

  const xAxes: string[] = useMemo(() => {
    return (
      currentWidget?.uniqueValues?.[xAxe] ||
      chartData?.map((d: any) => d[xAxe]) ||
      []
    );
  }, [chartData, currentWidget?.uniqueValues, xAxe]);

  const groupedData: any = useMemo(() => {
    return PunchcardChartGroupedData(currentWidget);
  }, [currentWidget]);

  const uniqueValuesKeys = useMemo(() => {
    return currentWidget?.uniqueValues
      ? Object.keys(currentWidget?.uniqueValues)
      : [];
  }, [currentWidget?.uniqueValues]);

  const groupByKey = useMemo(() => {
    return groupBy || uniqueValuesKeys?.[0];
  }, [groupBy, uniqueValuesKeys]);

  const uniqueValues = useMemo(() => {
    if (uniqueValuesKeys?.length && currentWidget?.uniqueValues) {
      return currentWidget?.uniqueValues[groupByKey!] || [];
    }
    return Object.keys(groupedData);
  }, [uniqueValuesKeys, currentWidget?.uniqueValues, groupByKey, groupedData]);

  const hasChartYOverflow = (uniqueValues.length || 1) > 5;

  const margin = {
    top: 0,
    right: 0,
    bottom: hasChartYOverflow ? 0 : 21,
    left: 80,
  };

  const width = bounds.width - margin.left - margin.right;
  const height =
    Math.max(uniqueValues.length * maxBubbleRadius * 2, bounds.height) -
    margin.top -
    margin.bottom;

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const numTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  const generateColorRangesCallback = useCallback(
    () =>
      generateColorRanges(
        variations,
        values,
        setColorRanges,
        currentWidget?.palette?.range
      ),
    [variations, values, currentWidget?.palette?.range]
  );

  useEffect(() => {
    const values: number[] = chartData?.map((item: any) => item[yAxe] || 0);

    const colorPaletteVariations = getSequentialColorsHex(
      styleId,
      currentWidget?.palette?.paletteId
    );

    setValues(values);
    setVariations(colorPaletteVariations?.slice());
  }, [currentWidget?.palette?.paletteId, chartData, styleId, yAxe]);

  useEffect(() => {
    if (variations?.length) {
      generateColorRangesCallback();
    }
  }, [variations?.length, values, generateColorRangesCallback]);

  //* Scales
  const minValue: number = useMemo(() => {
    if (!yAxe) {
      return 0;
    }
    return Math.min(...chartData?.map((d: any) => d?.[yAxe])) || 0;
  }, [chartData, yAxe]);

  const maxValue: number = useMemo(() => {
    if (!yAxe) {
      return 0;
    }
    return Math.max(...chartData?.map((d: any) => d?.[yAxe])) || 0;
  }, [chartData, yAxe]);

  const xScale = useMemo(() => {
    return scaleBand<string>()
      .domain(xAxes)
      .rangeRound([maxBubbleRadius, width - maxBubbleRadius])
      .paddingOuter(-0.5);
  }, [width, xAxes]);

  const yScale = useMemo(() => {
    return scaleBand<string>()
      .domain(uniqueValues)
      .rangeRound([maxBubbleRadius, height - maxBubbleRadius])
      .paddingOuter(-0.5);
  }, [height, uniqueValues]);

  const rScale = useMemo(() => {
    return scaleSqrt<number, number>()
      .domain([minValue, maxValue])
      .range([minBubbleRadius, maxBubbleRadius])
      .nice();
  }, [maxValue, minValue]);

  const colorScale = useMemo(() => {
    const domain = colorRanges.map((range) => range.start);
    const ranges = colorRanges.map((range) => range.color);

    return scaleLinear<string, string>()
      .domain(domain)
      .range(ranges)
      .unknown(getCurrentColor(currentWidget, "default", styleId));
  }, [colorRanges, currentWidget, styleId]);

  const xScaleNumTicksCalculated = calculateNumTicks({ width });

  const isReasonableAmountOfTicks =
    xScaleNumTicksCalculated <= xAxes.length &&
    xScaleNumTicksCalculated > 0 &&
    xAxes.length / xScaleNumTicksCalculated >= 1.5;

  const xScaleNumTicks = isReasonableAmountOfTicks
    ? xScaleNumTicksCalculated
    : xAxes.length;

  const xScaleTickValues = useMemo(
    () =>
      getScaleBandTickValues({
        tickCount: xScaleNumTicks,
        ticks: xAxes,
      }),
    [xScaleNumTicks, xAxes]
  );

  const xScaleTickLabelMaxLength = useMemo(
    () =>
      calculateLabelLength({
        width: width - margin.left - margin.right,
        tickValues: xScaleTickValues,
        tickFormatter: tickFormatter,
      }),
    [margin.left, margin.right, width, xScaleTickValues]
  );

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if ((showTooltip || currentWidget?.tooltip) && !recommended) {
        const { pageX, pageY, clientX, clientY } = event;
        const coords = { pageX, pageY, clientX, clientY };

        setTooltip({
          name: datum[groupByKey],
          data: {
            [xAxe as string]: String(datum[xAxe]),
            [yAxe as string]: String(datum[yAxe]),
          },
          coords,
        });
      }
    },
    [currentWidget?.tooltip, groupByKey, recommended, showTooltip, xAxe, yAxe]
  );

  const handleMouseLeave = useCallback(() => {
    if (showTooltip || currentWidget?.tooltip) {
      setTooltip(null);
    }
  }, [currentWidget?.tooltip, showTooltip]);

  const handleMouseMoveTickLabel = useCallback((event: any, datum: any) => {
    if (datum.length > maxLengthYAxisTickLabel) {
      setTickLabelTooltip({
        data: datum,
        x: event.pageX - 10,
        y: event.pageY,
      });
    }
  }, []);

  const handleMouseLeaveTickLabel = useCallback(() => {
    setTickLabelTooltip(null);
  }, []);

  const handleMouseOver = useCallback(
    function (self: any, svg: any) {
      if (!showTooltip && !currentWidget?.tooltip) {
        return;
      }

      svg
        .selectAll(".bubble-container")
        .transition()
        .duration(200)
        .attr("opacity", ".2");

      select(self).transition().duration(200).attr("opacity", "1");
    },
    [currentWidget?.tooltip, showTooltip]
  );

  const handleMouseOut = useCallback(
    function (svg: any) {
      if (!showTooltip && !currentWidget?.tooltip) {
        return;
      }

      svg
        .selectAll(".bubble-container")
        .transition()
        .duration(200)
        .attr("opacity", "1");
    },
    [currentWidget?.tooltip, showTooltip]
  );

  //* Chart
  const svgContainer = select(svgRef.current);

  const xAxisContainer = select(xAxisRef.current);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }

    if (xAxisRef.current) {
      xAxisRef.current.innerHTML = "";
    }

    if (!width || !height || !colorRanges?.length || !chartData?.length) {
      return;
    }

    svgContainer.attr("height", height + margin.top + margin.bottom);

    const svg = svgContainer
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    //* yGrid
    svg
      .append("g")
      .attr("class", "y-grid")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(-width)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* y-axis
    svg
      .append("g")
      .attr("class", "y-axis")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(0)
          .tickPadding(8)
          .tickFormat((s: string) => tickFormatter(s, maxLengthYAxisTickLabel))
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("text")
      .each(function (this: any) {
        const tickLabel = select(this);

        tickLabel
          .attr("class", "tick-label")
          .attr("dx", `-${margin.left - 10}px`)
          .attr("fill", "#5f6877")
          .attr("font-size", "11px")
          .attr("text-anchor", "start")
          .attr("class", (d: any) => {
            return d.length > maxLengthYAxisTickLabel ? "tick-label--long" : "";
          })
          .on("mousemove", handleMouseMoveTickLabel)
          .on("mouseleave", handleMouseLeaveTickLabel);
      });

    //* xGrid
    svg
      .append("g")
      .attr("class", "x-grid")
      .call(
        axisBottom(xScale)
          .tickSize(height)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* x-axis
    if (!hasChartYOverflow) {
      svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${height + margin.top})`)
        .call(
          axisBottom(xScale)
            .tickSizeOuter(0)
            .tickSize(0)
            .tickPadding(12)
            .tickValues(xScaleTickValues)
            .tickFormat((s: string) =>
              tickFormatter(s, xScaleTickLabelMaxLength)
            )
        )
        .call((g) => g.select(".domain").remove())
        .selectAll("text")
        .attr("fill", "#5f6877")
        .attr("font-size", "11px");
    } else {
      xAxisContainer
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(${margin.left},0)`)
        .call(
          axisBottom(xScale)
            .tickSizeOuter(0)
            .tickSize(0)
            .tickPadding(12)
            .tickValues(xScaleTickValues)
            .tickFormat((s: string) =>
              tickFormatter(s, xScaleTickLabelMaxLength)
            )
        )
        .call((g) => g.select(".domain").attr("stroke", "#939ba7"))
        .selectAll("text")
        .attr("fill", "#5f6877")
        .attr("font-size", "11px");
    }

    // * DataViz
    // Bubble Container
    svg
      .append("g")
      .selectAll("g")
      .data(chartData)
      .join("g")
      .attr("class", "bubble-container")
      .each(function (d: any) {
        const bubbleContainer = select(this);
        const color = colorScale(d[yAxe]);

        // Bubble
        bubbleContainer
          .append("circle")
          .attr("class", "bubble")
          .attr(
            "cx",
            (d: any) => (xScale(d[xAxe]) || 0) + xScale.bandwidth() / 2
          )
          .attr(
            "cy",
            (d: any) => (yScale(d[groupByKey]) || 0) + yScale.bandwidth() / 2
          )
          .attr("r", (d: any) => rScale(d[yAxe]))
          .attr("fill", color)
          .attr("fill-opacity", 0.2)
          .attr("stroke", color)
          .attr("stroke-width", 1);

        // Bubble Label
        if (rScale(d[yAxe]) >= minBubbleRadiusVisibleLabel) {
          bubbleContainer
            .append("text")
            .attr("class", "bubble-label")
            .attr("x", (xScale(d[xAxe]) || 0) + xScale.bandwidth() / 2)
            .attr("y", (yScale(d[groupByKey]) || 0) + yScale.bandwidth() / 2)
            .attr("font-size", "12px")
            .attr("fill", "#4e4e4e")
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .text(() => bubbleLabelFormatter(d[yAxe]));
        }
      })
      .on("mouseover", function () {
        handleMouseOver(this, svg);
      })
      .on("mouseout", () => {
        handleMouseOut(svg);
      })
      .on("mousemove", handleMouseMove)
      .on("mouseleave", handleMouseLeave);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, width, height, colorRanges]);

  if (!chartData || !Object.keys(chartData).length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <></>}
            {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["punchcardChart"]?.length + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {showLegend && currentWidget?.legend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="palette"
            colorRanges={colorRanges}
          />
        )}
      </HeaderWrapper>

      <div
        style={{
          height: "100%",
          flexGrow: 1,
          overflowY: hasChartYOverflow ? "scroll" : "visible",
        }}
      >
        <SVGStyled
          ref={(node) => {
            svgRef.current = node;
            measureRef(node);
          }}
          width="100%"
          height="100%"
        />
      </div>

      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            coords={tooltip.coords}
          />,
          document.body
        )}

      {hasChartYOverflow && (
        <svg
          style={{ flexShrink: 0 }}
          ref={xAxisRef}
          width="100%"
          height="22"
        ></svg>
      )}

      {tickLabelTooltip &&
        createPortal(
          <LabelTooltip
            x={tickLabelTooltip?.x}
            y={tickLabelTooltip?.y}
            data={tickLabelTooltip?.data}
          />,
          document.body
        )}
    </>
  );
};
