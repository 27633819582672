import React, { useEffect, useState } from "react";

import { Button } from "../Button";

import {
  ActionButton,
  ActionsPage,
  BackButton,
  DimensionSettingsFooterWrapper,
  DimensionSettingsIconsItemsWrapper,
  DimensionSettingsInput,
  DimensionSettingsInputLabel,
  DimensionSettingsInputWrapper,
  DimensionSettingsTypeLabel,
  DimensionSettingsTypeWrapper,
  DimensionSettingsWrapper,
  EditPage,
  Separator,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  requestUpdateDatasetById,
  updateDatasetData,
  updateDatasetHeader,
} from "../../store/slices/datasets";
import { updateFileData } from "../../store/slices/files";
import { getActiveModal } from "../../store/selectors/modals";
import {
  getSelectedDataset,
  getSelectedFileView,
} from "../../store/selectors/datasets";
import { toast } from "react-toastify";
import { RadioButtonComponent } from "./components/RadioButtonComponent";
import {
  ArrowDown,
  ArrowLeft,
  ArrowRepeat,
  Arrows,
  ArrowUp,
  Pencil,
  PinAngle,
  PinAngleFill,
  Star,
  StarFill,
} from "react-bootstrap-icons";
import {
  handleAddPrimaryTableForUploadedFile,
  handleResetAllColumns,
  handleResetAllColumnsForUploadedFile,
  handleSaveForAddedDataset,
  handleSetAsPrimary,
  sortTableAscending,
  sortTableDescending,
  tablePinColumn,
} from "./utils/tableActions";
import { getSubDimensions, settingsItemsData } from "./data/mock";
import { IDimensionSettings } from "./types/types";
import { Select } from "../Inputs/CustomSelect/Select";

export const DimensionSettings: React.FC<IDimensionSettings> = ({
  setShowDimensionSettings,
  showDimensionSettings: {
    header,
    top,
    left,
    suggestedType,
    key,
    errorsDetected,
    subtype,
  },
  setLastAction,
  pinedColumns,
  lastAction,
  filePath,
  edit,
  selectedDatasetContents,
  setSelectedDatasetContents,
  setPinedColumns,
  topSpace,
  isUploadedDataset,
  onlyEdit,
}) => {
  const dispatch = useDispatch();

  const activeModal = useSelector(getActiveModal);
  const fileSelected = useSelector(getSelectedFileView);
  const selectedDataset = useSelector(getSelectedDataset);

  const [columnName, setColumnName] = useState<string>(header);
  const [selectedCategory, setSelectedCategory] = useState<string>(
    suggestedType || ""
  );
  const [selectedSubtype, setSelectedSubtype] = useState<string>(subtype || "");
  const [isEdit, setIsEdit] = useState(false);

  const parsedSuggestions = fileSelected.parsedSuggestions;

  const isPinned = pinedColumns.includes(key);
  const isMaxPinnedColumns = pinedColumns.length < 6;

  const isPrimary = !!selectedDataset.fields?.find((item) => item.key === key)
    ?.primary;

  const isUploadedPrimary =
    parsedSuggestions &&
    parsedSuggestions.find((item) => item.objectKey === key)?.primary;

  const handleCheckboxChange = (category: string) => {
    setSelectedCategory(category);
  };

  const handleSaveForUploadedDataset = () => {
    if (activeModal.includes("datasetLibraryModal")) {
      if (selectedDataset !== null && selectedDataset.id) {
        const updatedFields = selectedDataset?.fields?.map((field) => {
          if (field.key === key) {
            return {
              ...field,
              label: columnName,
              type: selectedCategory,
              subtype: selectedCategory,
              icon: selectedCategory,
            };
          }
          return field;
        });

        dispatch(
          updateDatasetData({
            filePath,
            key,
            label: columnName,
            category: selectedCategory,
          })
        );

        dispatch(
          requestUpdateDatasetById({
            id: selectedDataset.id,
            name: selectedDataset.name,
            fields: updatedFields!,
          })
        );
      }
    } else {
      if (
        fileSelected.parsedSuggestions
          .map((suggestion) => suggestion.label)
          ?.filter((label: string) => label !== header)
          .includes(columnName)
      ) {
        toast.error("Column name already exists!");
      } else {
        dispatch(
          updateDatasetHeader({
            label: columnName,
            selectedType: selectedCategory,
            selectedSubType: selectedSubtype,
            objectKey: key,
          })
        );
        dispatch(
          updateFileData({
            key,
            newHeader: columnName,
            filePath: filePath,
            type: selectedCategory,
          })
        );
      }
    }

    setShowDimensionSettings(null);
  };

  const resetAllColumns = () => {
    if (isUploadedDataset) {
      handleResetAllColumnsForUploadedFile({
        filePath,
        dispatch,
        parsedSuggestions,
      });
    } else {
      handleResetAllColumns({
        dispatch,
        selectedDataset,
        key,
      });
    }
  };

  const handleAddPrimary = () => {
    if (isUploadedDataset) {
      handleAddPrimaryTableForUploadedFile({
        dispatch,
        parsedSuggestions,
        filePath,
        errorsDetected,
        key,
      });
    } else {
      handleSetAsPrimary({
        dispatch,
        selectedDataset,
        key,
      });
    }
  };

  const handleLastAction = (action: "sortA" | "sortD", key: string) => {
    if (["sortA", "sortD"].includes(action)) {
      setLastAction({
        key,
        subtype,
        ascending: action === "sortA",
        descending: action === "sortD",
      });
    }
  };

  const handleSave = () => {
    if (isUploadedDataset) {
      handleSaveForUploadedDataset();
    } else {
      handleSaveForAddedDataset({
        dispatch,
        selectedDataset,
        key,
        name: columnName,
        type: selectedCategory,
        subtype: selectedSubtype,
        closeMenu: () => setShowDimensionSettings(null),
      });
    }
  };

  useEffect(() => {
    if (selectedCategory !== suggestedType) {
      setSelectedSubtype("");
    } else {
      setSelectedSubtype(subtype);
    }
  }, [selectedCategory, subtype, suggestedType]);

  const checkIfDisabled = () => {
    if (errorsDetected) {
      return !selectedSubtype.length;
    }

    if (header !== columnName) {
      return false;
    }

    if (suggestedType !== selectedCategory) {
      return !selectedSubtype.length;
    }

    if (subtype !== selectedSubtype) {
      return false;
    }

    return true;
  };

  return (
    <DimensionSettingsWrapper $top={top - (topSpace || 0)} $left={left}>
      {onlyEdit || isEdit ? (
        <EditPage>
          {!onlyEdit && (
            <BackButton onClick={() => setIsEdit(false)}>
              <ArrowLeft /> Back
            </BackButton>
          )}

          <DimensionSettingsInputWrapper>
            <DimensionSettingsInputLabel>Name</DimensionSettingsInputLabel>
            <DimensionSettingsInput
              defaultValue={columnName}
              onChange={(e) => setColumnName(e.target.value)}
            />
          </DimensionSettingsInputWrapper>

          <Separator />

          <DimensionSettingsTypeWrapper>
            <DimensionSettingsTypeLabel>Type</DimensionSettingsTypeLabel>
            <DimensionSettingsIconsItemsWrapper>
              {settingsItemsData.map(({ type, label, icon, grayIcon }) => (
                <RadioButtonComponent
                  {...{
                    selectedCategory,
                    type,
                    handleCheckboxChange,
                    label,
                    icon,
                    grayIcon,
                  }}
                />
              ))}
            </DimensionSettingsIconsItemsWrapper>
          </DimensionSettingsTypeWrapper>

          <Separator />

          <DimensionSettingsInputWrapper>
            <Select
              label={"Choose subdimension"}
              options={getSubDimensions(selectedCategory)}
              value={selectedSubtype}
              handleChange={(value) => setSelectedSubtype(value)}
            />
          </DimensionSettingsInputWrapper>

          <Separator />

          <DimensionSettingsFooterWrapper>
            <Button
              onClick={handleSave}
              fullWidth
              variant={checkIfDisabled() ? "disabled" : "secondary"}
              size="full"
              name="Save"
              disabled={checkIfDisabled()}
            />
          </DimensionSettingsFooterWrapper>
        </EditPage>
      ) : (
        <ActionsPage>
          <ActionButton
            $active={lastAction.key === key && lastAction.ascending}
            onClick={() => {
              sortTableAscending({
                key,
                subtype,
                selectedDatasetContents,
                setSelectedDatasetContents,
              });
              setShowDimensionSettings(null);
              handleLastAction("sortA", key);
            }}
          >
            <ArrowUp />
            Sort Ascending
          </ActionButton>
          <ActionButton
            $active={lastAction.key === key && lastAction.descending}
            onClick={() => {
              sortTableDescending({
                key,
                subtype,
                selectedDatasetContents,
                setSelectedDatasetContents,
              });
              setShowDimensionSettings(null);
              handleLastAction("sortD", key);
            }}
          >
            <ArrowDown />
            Sort Descending
          </ActionButton>
          {(isPinned || isMaxPinnedColumns) && (
            <>
              <Separator />
              <ActionButton
                $active={isPinned}
                onClick={() => {
                  tablePinColumn({ key, pinedColumns, setPinedColumns });
                  setShowDimensionSettings(null);
                }}
              >
                {isPinned ? <PinAngleFill /> : <PinAngle />}
                {isPinned ? "No Pin" : "Pin Column"}
              </ActionButton>
            </>
          )}
          <Separator />
          <ActionButton>
            <Arrows />
            Autosize This Column
          </ActionButton>
          <ActionButton>
            <Arrows />
            Autosize All Column
          </ActionButton>
          <Separator />
          <ActionButton onClick={() => resetAllColumns()}>
            <ArrowRepeat />
            Reset All Columns
          </ActionButton>
          {!errorsDetected && (
            <ActionButton
              $active={isPrimary || isUploadedPrimary}
              onClick={() => handleAddPrimary()}
            >
              {isPrimary || isUploadedPrimary ? <StarFill /> : <Star />}
              {isPrimary || isUploadedPrimary
                ? "Remove from primary"
                : "Set as primary"}
            </ActionButton>
          )}
          {edit && (
            <>
              <Separator />
              <ActionButton onClick={() => setIsEdit(true)}>
                <Pencil />
                Edit
              </ActionButton>
            </>
          )}
        </ActionsPage>
      )}
    </DimensionSettingsWrapper>
  );
};
