import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ButtonsWrapper,
  CheckBoxWrapper,
  ContentContainer,
  DataListHeader,
  DatasetOverviewLoaderWrapper,
  DatasetsButtons,
  HeaderButtons,
  ImagesIcon,
  ModalContent,
  ModalOverlay,
  ModalWrapper,
  SelectedDataSets,
  SelectedDataSetsList,
  SelectedDataSetsListHeader,
  TablesContainer,
  TablesContent,
  TablesHeader,
} from "./styles";
import {
  requestGetDatasetContents,
  resetDatasetContents,
} from "../../../store/slices/datasets";
import { Search } from "../../SearchBar";
import {
  getDatasetContents,
  getDatasetLoading,
  getNewProjectSelectedDatasets,
  getSelectedDataset,
} from "../../../store/selectors/datasets";
import { CloseIcon } from "../styles";
import { Loader } from "../../Loader";
import { getIconSrc } from "../../../helpers/getIconType";
import { Button } from "../../Button";
import { ButtonWrapper } from "../UploadedFilePreview/styles";
import { DimensionSettingsInterface } from "../../../models/Files";
import { DimensionSettings } from "../../DimensionSettings";
import { handleColumnClick } from "../../../helpers/handleColumnClick";
import { ILastAction } from "../../../pages/Resources/pages/Datasets/pages/DatasetPreview";
import { CheckBox } from "../../Inputs/CustomCheckbox/CheckBox";
import {
  sortTableAscending,
  sortTableDescending,
} from "../../DimensionSettings/utils/tableActions";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { getModalData } from "../../../store/selectors/modals";
import DataSetItem from "./components/DataSetItem";
import useMeasure from "react-use-measure";
import {
  HeaderRow,
  HeadingIcon,
  HeadingText,
  Icon,
  More,
  Primary,
  StarIcon,
  Table,
  TableHeading,
  TableRowCell,
  TableRowDataSet,
  TablesWrapper,
} from "../DatasetsOverview/styles";

interface DatasetsOverviewModalProps {
  closeModal: () => void;
}

export const SelectedDatasetOverview = ({
  closeModal,
}: DatasetsOverviewModalProps) => {
  const ref = useRef(null);
  const [tableRef, tableBounds] = useMeasure();
  const rowsRef = useRef<Array<HTMLDivElement | null>>([]);
  const dispatch = useDispatch();

  const isLoading = useSelector(getDatasetLoading);
  const selectedDataset = useSelector(getSelectedDataset);
  const newProjectSelectedDatasets = useSelector(getNewProjectSelectedDatasets);
  const datasetContents = useSelector(getDatasetContents);
  const modalData = useSelector(getModalData);

  const [limit, setLimit] = useState(30);
  const [searchInput, setSearchInput] = useState<string>("");
  const [lastAction, setLastAction] = useState<ILastAction>({});
  const [showDimensionSettings, setShowDimensionSettings] =
    useState<DimensionSettingsInterface | null>(null);
  const [selectedDatasetContents, setSelectedDatasetContents] = useState<any>(
    []
  );
  const [pinedColumns, setPinedColumns] = useState<string[]>([]);
  const [maxRowHeight, setMaxRowHeight] = useState(0);

  const headers = selectedDataset?.fields;
  const filteredHeaders = headers?.filter((item) =>
    item.label.toLowerCase().includes(searchInput.toLowerCase())
  );

  const columnWidth =
    tableBounds.width > 195 * filteredHeaders?.length!
      ? tableBounds.width / filteredHeaders?.length!
      : undefined;

  const getDatasetContentsR = (plus?: number) => {
    const count = datasetContents?.count;
    const plusLimit = limit + (plus || 0);
    const newLimit = plusLimit > count ? count : plusLimit;
    dispatch(
      requestGetDatasetContents({
        id: selectedDataset?.id || selectedDataset?.sourceId || "",
        limit: newLimit,
        skip: 0,
      })
    );
    setLimit(newLimit);
  };

  useEffect(() => {
    if (!datasetContents) return;

    const newData = datasetContents?.items?.map(
      (contentItem: any) => contentItem
    );

    if (!lastAction || !selectedDatasetContents) {
      setSelectedDatasetContents(newData);
      return;
    }

    if (lastAction.ascending) {
      sortTableAscending({
        key: lastAction.key!,
        subtype: lastAction.subtype!,
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else if (lastAction.descending) {
      sortTableDescending({
        key: lastAction.key!,
        subtype: lastAction.subtype!,
        selectedDatasetContents,
        setSelectedDatasetContents,
        data: newData,
      });
    } else {
      setSelectedDatasetContents(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetContents]);

  useEffect(() => {
    if (selectedDataset && (selectedDataset.id || selectedDataset.sourceId))
      getDatasetContentsR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDataset]);

  useEffect(() => {
    const heights = rowsRef.current.map((row) => row?.offsetHeight || 0);
    setMaxRowHeight(Math.max(...heights));
  }, [selectedDatasetContents, filteredHeaders, pinedColumns]);

  useOutsideClick(ref, () => {
    setShowDimensionSettings(null);
  });

  return (
    <ModalOverlay onClick={closeModal}>
      <ModalWrapper
        onClick={(e) => e.stopPropagation()}
        ref={ref}
        $isSelectedDatasetPreview={modalData.isSettingModal}
      >
        <ModalContent>
          {isLoading ? (
            <DatasetOverviewLoaderWrapper>
              <Loader />
            </DatasetOverviewLoaderWrapper>
          ) : (
            <ContentContainer>
              <SelectedDataSets>
                <SelectedDataSetsList>
                  <SelectedDataSetsListHeader>
                    Data Resources
                  </SelectedDataSetsListHeader>

                  <DataListHeader>
                    <ImagesIcon /> Datasets:
                  </DataListHeader>
                  {newProjectSelectedDatasets.map((dataset, index) => (
                    <DataSetItem
                      dataset={dataset}
                      key={index}
                      filePath={dataset.filePath}
                      hideRemove={dataset.sourceId === selectedDataset.sourceId}
                      active={dataset.sourceId === selectedDataset.sourceId}
                    />
                  ))}
                </SelectedDataSetsList>
                <DatasetsButtons>
                  <Button
                    disabled={datasetContents?.count <= limit}
                    onClick={() => {
                      closeModal();
                      dispatch(resetDatasetContents());
                    }}
                    size="small"
                    variant="neutral"
                    name="Cancel"
                  />
                  <Button
                    disabled={datasetContents?.count <= limit}
                    onClick={() => {}}
                    size="small"
                    variant="primary"
                    name="Save changes"
                  />
                </DatasetsButtons>
              </SelectedDataSets>

              <TablesContainer>
                <TablesHeader>
                  {selectedDataset?.name.charAt(0).toUpperCase()! +
                    selectedDataset?.name.slice(1, -4)}

                  <HeaderButtons>
                    <Search
                      width="xs"
                      placeholder="Search"
                      onChange={(value: string) => setSearchInput(value)}
                    />
                    <CloseIcon
                      onClick={() => {
                        closeModal();
                        dispatch(resetDatasetContents());
                      }}
                    />
                  </HeaderButtons>
                </TablesHeader>
                <TablesContent>
                  <TablesWrapper ref={tableRef} $noBorder>
                    {/*key: previewTable - CreateProject Selected Datasets Preview Dataset */}

                    {!!pinedColumns.length && (
                      <Table $pined={true}>
                        {
                          <HeaderRow>
                            {filteredHeaders
                              ?.filter((item) =>
                                pinedColumns.includes(item.key)
                              )
                              .map((datasetItem: any, idx) => (
                                <TableHeading $width={columnWidth}>
                                  {datasetItem.primary && (
                                    <Primary>
                                      <StarIcon></StarIcon>
                                    </Primary>
                                  )}
                                  <>
                                    <CheckBoxWrapper>
                                      <CheckBox onChange={() => {}}></CheckBox>
                                    </CheckBoxWrapper>
                                  </>
                                  <HeadingIcon>
                                    <Icon
                                      src={getIconSrc({
                                        type: datasetItem.type,
                                        subtype: datasetItem.subtype,
                                      })}
                                    />
                                  </HeadingIcon>
                                  <HeadingText>
                                    {datasetItem.label
                                      ?.charAt(0)
                                      ?.toUpperCase()! +
                                      datasetItem.label?.slice(1)}
                                  </HeadingText>
                                  <ButtonsWrapper>
                                    <More
                                      onClick={(e) => {
                                        setShowDimensionSettings(
                                          handleColumnClick({
                                            header: datasetItem.label,
                                            event: e,
                                            key: datasetItem.key,
                                            errorsDetected: false,
                                            suggestedType: datasetItem.type,
                                            subtype: datasetItem.subtype,
                                          })
                                        );
                                      }}
                                    />
                                  </ButtonsWrapper>
                                </TableHeading>
                              ))}
                          </HeaderRow>
                        }

                        {selectedDatasetContents.map(
                          (item: any, idx: number) => {
                            return (
                              <TableRowDataSet
                                key={idx}
                                ref={(el) => (rowsRef.current[idx] = el)}
                                style={{
                                  height: maxRowHeight
                                    ? `${maxRowHeight}px`
                                    : "auto",
                                }}
                              >
                                {filteredHeaders
                                  ?.filter((item) =>
                                    pinedColumns.includes(item.key)
                                  )
                                  .map((header) => (
                                    <TableRowCell $width={columnWidth}>
                                      {item[header.key] || "No data"}
                                    </TableRowCell>
                                  ))}
                              </TableRowDataSet>
                            );
                          }
                        )}
                      </Table>
                    )}
                    <Table>
                      {
                        <HeaderRow>
                          {filteredHeaders
                            ?.filter((item) => !pinedColumns.includes(item.key))
                            .map((datasetItem: any, idx) => (
                              <TableHeading $width={columnWidth}>
                                {datasetItem.primary && (
                                  <Primary>
                                    <StarIcon></StarIcon>
                                  </Primary>
                                )}
                                <>
                                  <CheckBoxWrapper>
                                    <CheckBox onChange={() => {}}></CheckBox>
                                  </CheckBoxWrapper>
                                </>
                                <HeadingIcon>
                                  <Icon
                                    src={getIconSrc({
                                      type: datasetItem.type,
                                      subtype: datasetItem.subtype,
                                    })}
                                  />
                                </HeadingIcon>
                                <HeadingText>
                                  {datasetItem.label
                                    ?.charAt(0)
                                    ?.toUpperCase()! +
                                    datasetItem.label?.slice(1)}
                                </HeadingText>
                                <ButtonsWrapper>
                                  <More
                                    onClick={(e) => {
                                      setShowDimensionSettings(
                                        handleColumnClick({
                                          header: datasetItem.label,
                                          event: e,
                                          key: datasetItem.key,
                                          errorsDetected: false,
                                          suggestedType: datasetItem.type,
                                          subtype: datasetItem.subtype,
                                        })
                                      );
                                    }}
                                  />
                                </ButtonsWrapper>
                              </TableHeading>
                            ))}
                        </HeaderRow>
                      }

                      {selectedDatasetContents.map((item: any, idx: number) => {
                        return (
                          <TableRowDataSet
                            key={idx}
                            ref={(el) => (rowsRef.current[idx] = el)}
                            style={{
                              height: maxRowHeight
                                ? `${maxRowHeight}px`
                                : "auto",
                            }}
                          >
                            {filteredHeaders
                              ?.filter(
                                (item) => !pinedColumns.includes(item.key)
                              )
                              .map((header) => (
                                <TableRowCell $width={columnWidth}>
                                  {item[header.key] || "No data"}
                                </TableRowCell>
                              ))}
                          </TableRowDataSet>
                        );
                      })}
                    </Table>
                  </TablesWrapper>
                  <ButtonWrapper>
                    <Button
                      disabled={datasetContents?.count <= limit}
                      onClick={() => {
                        getDatasetContentsR(30);
                      }}
                      size="small"
                      variant="primary"
                      name="Load More"
                    ></Button>
                  </ButtonWrapper>
                </TablesContent>
              </TablesContainer>
            </ContentContainer>
          )}
        </ModalContent>
        {showDimensionSettings && (
          <div ref={ref}>
            <DimensionSettings
              filePath={""}
              showDimensionSettings={showDimensionSettings}
              lastAction={lastAction}
              pinedColumns={pinedColumns}
              selectedDatasetContents={selectedDatasetContents}
              setShowDimensionSettings={setShowDimensionSettings}
              setLastAction={setLastAction}
              setPinedColumns={setPinedColumns}
              setSelectedDatasetContents={setSelectedDatasetContents}
              topSpace={-50}
            />
          </div>
        )}
      </ModalWrapper>
    </ModalOverlay>
  );
};
