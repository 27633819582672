import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  DatasetOverviewLoaderWrapper,
  HeadingIcon,
  HeadingText,
  Icon,
  Table,
  TableColumn,
  TableHeading,
  TableRow,
} from "../../../../../../../components/Modals/DatasetsOverview/styles";
import { Loader } from "../../../../../../../components/Loader";
import { getIconSrc } from "../../../../../../../helpers/getIconType";
import { ButtonWrapper } from "../../../../../../../components/Modals/UploadedFilePreview/styles";
import { DatasetPreviewPageWrapper, DatasetPreviewPageContent } from "./styles";
import { getSelectedSubTopics } from "../../../../../../../store/selectors/topics";
import { getDataProviderDatasetContent } from "../../../../../../../api/dataProviders";
import { ServerResponse } from "../../../../../../../models";
import { Button } from "../../../../../../../components/Button";

interface Props {
  disableHeader?: boolean;
  filters?: {
    years?: string[];
    locations?: string[];
  };
}

export const PublicDatasetPreview: React.FC<Props> = ({
  disableHeader,
  filters,
}) => {
  const { id, datasetId } = useParams();
  const [data, setData] = useState<any>();
  const [limit, setLimit] = useState(25);
  const selectedSubTopics = useSelector(getSelectedSubTopics);
  const selectedDataset = useMemo(
    () =>
      selectedSubTopics &&
      Object.values(selectedSubTopics)?.find((topic) =>
        topic.find((subtopic) => subtopic.id === datasetId)
      )?.find((subtopic) => subtopic.id === datasetId),
    [datasetId, selectedSubTopics]
  );
  const variables = useMemo(
    () => selectedDataset?.variables,
    [selectedDataset]
  );

  const getData = async (plus: number) => {
    const variablesRequestParam: string[] = [];
    selectedDataset?.variables?.forEach((variable) => {
      variablesRequestParam.push(String(variable?.name));
    });
    if (
      !variablesRequestParam.length ||
      !filters?.years?.length ||
      !filters?.locations?.length
    ) {
      return;
    }
    const count = data?.length;
    const plusLimit = limit + (plus || 0);
    const newLimit = plusLimit > count ? count : plusLimit;
    const { response }: ServerResponse = await getDataProviderDatasetContent(
      id!,
      {
        datasetId: datasetId!,
        location: filters?.locations?.[0]?.toLocaleLowerCase() || "us",
        topicId: selectedDataset?.topicId!,
        variables: variablesRequestParam,
        years: filters?.years || [],
        rowsToSkip: 0,
        numberOfRows: newLimit,
      }
    );
    setData(response?.data);
    setLimit(newLimit);
  };

  useEffect(() => {
    getData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId, variables]);

  return (
    <DatasetPreviewPageWrapper>
      <DatasetPreviewPageContent>
        {!data ? (
          <DatasetOverviewLoaderWrapper>
            <Loader />
          </DatasetOverviewLoaderWrapper>
        ) : (
          <>
            <Table $row={true}>
              {data &&
                Object.keys(data)?.map((datasetKey, idx) => {
                  const rowData = variables?.find(
                    (el) => el.title === datasetKey
                  ) || {
                    dataType: datasetKey === "year" ? "datetime" : "location",
                  };
                  return (
                    <TableColumn key={idx}>
                      <TableHeading>
                        <HeadingIcon>
                          <Icon
                            src={getIconSrc({
                              type: rowData.dataType!,
                              subtype: "",
                            })}
                          />
                        </HeadingIcon>
                        <HeadingText>
                          {datasetKey?.charAt(0)?.toUpperCase()! +
                            datasetKey?.slice(1)}
                        </HeadingText>
                      </TableHeading>
                      {data?.[datasetKey]?.map((item: any, idx: number) => {
                        return (
                          <TableRow key={idx}>
                            {item ? item : "No data"}
                          </TableRow>
                        );
                      })}
                    </TableColumn>
                  );
                })}
            </Table>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  getData(25);
                }}
                size="small"
                variant="primary"
                name="Load More"
                disabled={data?.length <= limit}
              />
            </ButtonWrapper>
          </>
        )}
      </DatasetPreviewPageContent>
    </DatasetPreviewPageWrapper>
  );
};
