import React, { useMemo, useState } from "react";
import { Upload, CheckCircle, PlusLg } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "../../Button";
import {
  ModalFooterWrapper,
  ModalContainer,
  ModalContent,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  Actions,
  OverviewContent,
  BackIcon,
  ModalHeadingWrapperUpper,
  ModalHeadingWrapperTabs,
  ModalHeadingWrapperTab,
  DataCount,
  SearchIcon,
} from "../styles";
import { uniq } from "lodash";
import {
  removeAllSelectedFiles,
  resetNewProjectSelectedFile,
  setNewProjectSelectedFiles,
} from "../../../store/slices/datasets";
import {
  closeActiveModal,
  resetActiveModal,
  setActiveModal,
} from "../../../store/slices/modals";
import { LibraryDatasetOverview } from "../LibraryDatasetOverview";
import { Search } from "../../SearchBar";
import {
  getAllDatasets,
  getNewProjectSelectedDatasets,
  getSelectedFiles,
} from "../../../store/selectors/datasets";
import { getModalBackOptions } from "../../../store/selectors/modals";
import { FileLibraryItem } from "../../../models/Files";
import MyData from "./components/MyData";
import PublicData from "./components/PublicData";
import APIs from "./components/Apis";

export const DatasetLibraryModal = () => {
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false);
  const [activeTab, setActiveTab] = useState("My Data");
  const [libraryDatasetOverviewOpen, setLibraryDatasetOverviewOpen] =
    useState(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [dimensionsSelected, setDimensionsSelected] = useState<any>([]);
  const allDatasets = useSelector(getAllDatasets);
  const selectedFiles = useSelector(getSelectedFiles);
  const projectSelectedDatasets = useSelector(getNewProjectSelectedDatasets);
  const modalBackOption = useSelector(getModalBackOptions);

  const navigate = useNavigate();

  const libraryDatasetsIds: string[] = useMemo(() => {
    return uniq([
      ...selectedFiles.map((i) => i.id),
      ...projectSelectedDatasets.map((el) => String(el.sourceId)),
    ]);
  }, [selectedFiles, projectSelectedDatasets]);

  const newProjectSelectedDatasets = useMemo(() => {
    return allDatasets.items
      .filter((dataset) =>
        libraryDatasetsIds.includes(dataset.id || dataset.sourceId || "")
      )
      .map((dataset) => {
        const selectedDataset = selectedFiles.find(
          (file) => file.id === dataset.id
        );
        if (selectedDataset) {
          const selectedHeaders = selectedDataset.fields;
          return {
            ...dataset,

            fields: selectedHeaders,
          };
        }
        return null;
      })
      .filter((dataset) => dataset !== null);
  }, [allDatasets.items, libraryDatasetsIds, selectedFiles]);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(removeAllSelectedFiles());
      dispatch(closeActiveModal());
      dispatch(resetNewProjectSelectedFile());
    }, 400);
  };

  const Tabs = [
    {
      name: "My Data",
      items: libraryDatasetsIds.length,
    },
    {
      name: "Public Data",
      items: libraryDatasetsIds.length,
    },
    {
      name: "APIs",
      items: 0,
    },
    // {
    //   name: "Files",
    //   items: 0,
    // },
  ];

  return (
    <ModalOverlay $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $fileOverviewOpen={libraryDatasetOverviewOpen}
      >
        <ModalHeadingWrapper
          $fileOverviewOpen={libraryDatasetOverviewOpen}
          $dataLibrary
        >
          <ModalHeadingWrapperUpper>
            <ModalContainer>
              {modalBackOption.includes("datasetLibraryModal") && (
                <BackIcon
                  onClick={() => {
                    dispatch(closeActiveModal());
                    dispatch(removeAllSelectedFiles());
                    dispatch(setActiveModal({ id: "createProjectModal" }));
                  }}
                />
              )}
              <ModalHeading>Data Library</ModalHeading>
            </ModalContainer>
            <Search
              width="xs"
              placeholder="Search"
              onChange={(value: string) => setSearchInput(value)}
            />
          </ModalHeadingWrapperUpper>

          <ModalHeadingWrapperTabs>
            {Tabs.map((item) => (
              <ModalHeadingWrapperTab
                onClick={() => setActiveTab(item?.name)}
                $active={activeTab === item?.name}
              >
                {item?.name}{" "}
                {activeTab === item?.name && !!item?.items && <DataCount>{item?.items}</DataCount>}
              </ModalHeadingWrapperTab>
            ))}
          </ModalHeadingWrapperTabs>
        </ModalHeadingWrapper>

        <OverviewContent $dataLibrary>
          <ModalContent $fileOverviewOpen={libraryDatasetOverviewOpen}>
            {activeTab === "My Data" && (
              <MyData
                allDatasets={allDatasets}
                searchInput={searchInput}
                libraryDatasetsIds={libraryDatasetsIds}
                setDimensionsSelected={setDimensionsSelected}
                libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
                setLibraryDatasetOverviewOpen={setLibraryDatasetOverviewOpen}
              />
            )}
            {activeTab === "Public Data" && (
              <PublicData
                allDatasets={allDatasets}
                searchInput={searchInput}
                libraryDatasetsIds={libraryDatasetsIds}
                setDimensionsSelected={setDimensionsSelected}
                libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
                setLibraryDatasetOverviewOpen={setLibraryDatasetOverviewOpen}
              />
            )}
            {activeTab === "APIs" && (
              <APIs
                allDatasets={allDatasets}
                searchInput={searchInput}
                libraryDatasetsIds={libraryDatasetsIds}
                setDimensionsSelected={setDimensionsSelected}
                libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
                setLibraryDatasetOverviewOpen={setLibraryDatasetOverviewOpen}
              />
            )}
            {/* {activeTab === "Files" && (
              <Files
                allDatasets={allDatasets}
                searchInput={searchInput}
                libraryDatasetsIds={libraryDatasetsIds}
                setDimensionsSelected={setDimensionsSelected}
                libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
                setLibraryDatasetOverviewOpen={setLibraryDatasetOverviewOpen}
              />
            )} */}
          </ModalContent>
          {libraryDatasetOverviewOpen && (
            <LibraryDatasetOverview
              setLibraryDatasetOverview={setLibraryDatasetOverviewOpen}
              dimensionsSelected={dimensionsSelected}
            />
          )}
        </OverviewContent>

        <ModalFooterWrapper $fileOverviewOpen={libraryDatasetOverviewOpen}>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            {activeTab === "My Data" && (
              <Button
                name="Upload more"
                onClick={() => {
                  dispatch(removeAllSelectedFiles());
                  dispatch(closeActiveModal());
                  dispatch(
                    setActiveModal({ id: "uploadDatasetModal", back: true })
                  );
                }}
                variant="primary"
                size="medium"
                icon={<Upload />}
              />
            )}
            {activeTab === "Public Data" && (
              <Button
                name="Search Public Data"
                onClick={() => {
                  dispatch(closeActiveModal());
                  navigate("/resources/public-resources/search");
                }}
                variant="primary"
                size="medium"
                icon={<SearchIcon />}
              />
            )}
            {activeTab === "APIs" && (
              <Button
                name="Connect API"
                onClick={() => {}}
                variant="primary"
                size="medium"
                icon={<PlusLg />}
              />
            )}
            {/* {activeTab === "Files" && (
              <Button
                name="Add files"
                onClick={() => {
                  dispatch(setActiveModal({ id: "uploadFilesModal" }));
                }}
                variant="primary"
                size="medium"
                icon={<PlusLg />}
              />
            )} */}
            <Button
              name="Add"
              onClick={() => {
                dispatch(
                  setNewProjectSelectedFiles(
                    newProjectSelectedDatasets.map((item) => {
                      return {
                        name: item?.name,
                        filePath: item?.filePath,
                        fields: item?.fields,
                        sourceId: item?.id,
                        source: item?.source,
                        fieldsAi: item?.fieldsAi,
                        descriptionAi: item?.descriptionAi,
                        sourceType: item?.sourceType,
                      };
                    }) as FileLibraryItem[]
                  )
                );
                dispatch(resetActiveModal());
                dispatch(setActiveModal({ id: "createProjectModal" }));
              }}
              variant={selectedFiles.length ? "secondary" : "disabled"}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
