import styled from "styled-components";
import { NavLink } from "react-router-dom";

import {
  ModalWrapper as BaseModalWrapper,
} from "../styles";

export const ModalWrapper = styled(BaseModalWrapper)`
    display: flex;
    flex-direction: column;
    background: #F7F7F8;
`;

export const ModalHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
`;

export const ModalHeaderContainerItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    strong {
        margin-right: 4px;
    }
`;

export const ModalContentContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 560px;
    max-width: 100%;
    margin: 0 auto;
`;

export const ModalTitle = styled.h1`
    color: #000417;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
`;

export const ModalDescription = styled.p`
    color: ${({ theme }) => theme.neutralTextWeak};
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 48px;
`;

export const ModalFooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
`;

export const ModalTicketLink = styled(NavLink)`
    display: inline-block;
    font-weight: 400;
    background-color: #fff;
    padding: 6px 8px;
    color: ${({ theme }) => theme.primaryText};
    border-radius: 16px;
    box-shadow: 0px 2px 2px rgba(34, 36, 44, 0.06), 0px 2px 4px 1px rgba(34, 36, 44, 0.04);
`;


