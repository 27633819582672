import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Arrow,
  CheckBoxWrapper,
  DatasetWrapper,
  ImportedFilesContainer,
  MyDataHeader,
  Name,
  Provider,
  Trash,
  UploadedOn,
} from "./styles";
import { CheckBox } from "../../../../../Inputs/CustomCheckbox/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { getAllDatasets } from "../../../../../../store/selectors/datasets";
import { FileLibraryItem } from "../../../../../../models/Files";
import { requestGetAllDatasets } from "../../../../../../store/slices/datasets";
import PublicDataRow from "../PublicDataRow";

type Props = {
  setDimensionsSelected: Dispatch<SetStateAction<any>>;
  setFileOverviewOpen: Dispatch<SetStateAction<boolean>>;
  searchInput: string;
  libraryDatasetsIds: string[];
  libraryDatasetOverviewOpen?: boolean;
};

interface IFilesWithSelectedProp extends FileLibraryItem {
  checkboxState: "checked" | "indeterminate" | "none";
}

const PublicDataList = ({
  setFileOverviewOpen,
  setDimensionsSelected,
  searchInput,
  libraryDatasetsIds,
  libraryDatasetOverviewOpen,
}: Props) => {
  const dispatch = useDispatch();

  const { items: datasets } = useSelector(getAllDatasets);
  const [dataSetList, setDataSetList] = useState<IFilesWithSelectedProp[]>(
    datasets.map((item) => ({
      ...item,
      checkboxState: libraryDatasetsIds.find(
        (el) => el === item.id || el === item.sourceId
      )
        ? "checked"
        : "none",
    }))
  );
  useEffect(() => {
    setDataSetList(
      datasets.map((item) => ({
        ...item,
        checkboxState: libraryDatasetsIds.find(
          (el) => el === item.id || el === item.sourceId
        )
          ? "checked"
          : "none",
      }))
    );
  }, [datasets, libraryDatasetsIds]);

  const filteredDatasets = dataSetList
    .filter((item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    )
    // @ts-ignore
    .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

  useEffect(() => {
    dispatch(requestGetAllDatasets({
      limit: 1000,
      skip: 0,
      sourceType: 'provider'
    }));
  }, [dispatch]);

  return (
    <DatasetWrapper $full>
      <ImportedFilesContainer $noGap>
        <MyDataHeader>
          <CheckBoxWrapper>
            <CheckBox onChange={() => { }}></CheckBox>
          </CheckBoxWrapper>
          <Name>Name</Name>
          <Provider>Provider</Provider>
          <UploadedOn>
            Uploaded on <Arrow />
          </UploadedOn>
          <Trash></Trash>
        </MyDataHeader>
        {filteredDatasets.map((dataSet) => (
          <PublicDataRow
            file={dataSet}
            key={dataSet.id + dataSet.name}
            setFileOverviewOpen={setFileOverviewOpen}
            isChecked={dataSet.checkboxState}
            libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
            onChange={(value: "checked" | "indeterminate" | "none") => {
              setDataSetList((prev) =>
                prev.map((prevItem) =>
                  prevItem.id === dataSet.id
                    ? { ...prevItem, checkboxState: value }
                    : prevItem
                )
              );
            }}
            setDimensionsSelected={setDimensionsSelected}
          />
        ))}
      </ImportedFilesContainer>
    </DatasetWrapper>
  );
};

export default PublicDataList;
