import { useState } from "react";
import { useDispatch } from "react-redux";
import { closeActiveModal } from "../../../store/slices/modals";
import { Button } from "../../Button";
import { AiIcon } from "../../../assets";
import { PlusLg, XLg, List, QuestionCircleFill } from "react-bootstrap-icons";

import {
  ModalFooterWrapper,
  ModalHeadingWrapper,
  ModalOverlay,
} from "../styles";

import {
    ModalHeaderContainer,
    ModalHeaderContainerItem,
    ModalFooterContainer,
    ModalTicketLink,
    ModalWrapper,
    ModalContentContainer,
    ModalTitle,
    ModalDescription
} from "./styles";


interface ModalProps {
  onClose: () => void;
};

const AiChatModal = ({ onClose }: ModalProps) => {
    const [isClosing, setIsClosing] = useState(false);

    const dispatch = useDispatch();

    const handleOnClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
            dispatch(closeActiveModal());
        }, 400);
    };

    return (
        <ModalOverlay $isClosing={isClosing} onClick={handleOnClose}>
            <ModalWrapper $isClosing={isClosing} onClick={(e) => e.stopPropagation()}>
                <ModalHeadingWrapper>
                    <ModalHeaderContainer>
                        <ModalHeaderContainerItem>
                            <AiIcon />
                            <span>
                                <strong>Fuse</strong>
                                Assistant
                            </span>
                            <List />
                        </ModalHeaderContainerItem>
                        <ModalHeaderContainerItem>
                            <Button
                                variant="primary"
                                size="medium"
                                name="New Session"
                                icon={<PlusLg />}
                                onClick={() => console.log(123)}
                            />
                            <Button
                                round
                                variant="neutral"
                                size="medium"
                                icon={<XLg />}
                                onClick={handleOnClose}
                            />
                        </ModalHeaderContainerItem>
                    </ModalHeaderContainer>
                </ModalHeadingWrapper>
                <ModalContentContainer>
                    <ModalTitle>
                        Hi Robert! 
                    </ModalTitle>
                    <ModalDescription>
                        I'm Fuse Assistant, your personal Chat Bot Assistant. 
                        <br />
                        How can I help you?
                    </ModalDescription>
                </ModalContentContainer>
                <ModalFooterWrapper>
                    <ModalFooterContainer>
                        <QuestionCircleFill />
                        Need help?
                        <ModalTicketLink to="/">
                            Create a Ticket
                        </ModalTicketLink>
                    </ModalFooterContainer>
                </ModalFooterWrapper>
            </ModalWrapper>
        </ModalOverlay>
  );
};

export default AiChatModal;