import React, { useState } from "react";
import { FileLibraryItem } from "../../../../../models/Files";
import { DatasetDimensions, DatasetName, ItemWrapper } from "./styles";
import Badge from "../../../../Badge";
import { getIconSrc } from "../../../../../helpers/getIconType";

interface DatasetsItemInterface {
  dataset?: Partial<FileLibraryItem>;
  id?: string;
  filePath?: string;
  hideRemove?: boolean;
  selectedDimensions?: string[] | undefined;
  currentKeys?: string[];
  isDraggable?: boolean;
  disablePreview?: boolean;
  active?: boolean;
}
const DataSetItem = ({
  dataset,
  id,
  filePath,
  hideRemove,
  selectedDimensions,
  currentKeys,
  isDraggable,
  disablePreview,
  active,
}: DatasetsItemInterface) => {

  const [showDimensions, setShowDimensions] = useState<boolean>(false);

  return (
    <ItemWrapper $isActive={active}>
      <DatasetName
        onClick={() => setShowDimensions((prev) => !prev)}
        $isOpen={showDimensions}
      >
        {dataset?.name}
      </DatasetName>
      {showDimensions && (
        <DatasetDimensions>
          {dataset?.fields?.map((field, idx: number) => (
            <Badge
              key={idx}
              name={field.label}
              onClick={() => {}}
              keyName={field.key}
              type={field.type}
              subtype={field.subtype}
              icon={getIconSrc({ type: field.type, subtype: field.subtype })}
              isDraggable={isDraggable}
              selectedItems={selectedDimensions}
            />
          ))}
        </DatasetDimensions>
      )}
    </ItemWrapper>
  );
};

export default DataSetItem;
